import React, { useState } from 'react'
import Layout from '../../components/layout'
import { Container, Row, Col, ModalBody, Modal } from 'reactstrap'
import styled from 'styled-components'
import { graphql, StaticQuery } from 'gatsby'
import Img from "gatsby-image"
import Logo1 from '../../images/socialResponsibility/logo1.png'
import Logo2 from '../../images/socialResponsibility/logo2.jpg'
import Logo3 from '../../images/socialResponsibility/logo3.png'
import Logo4 from '../../images/socialResponsibility/logo4.png'
import Logo5 from '../../images/socialResponsibility/logo5.png'
import Img1 from '../../images/socialResponsibility/03.jpg'
import Img2 from '../../images/socialResponsibility/5.jpg'
import BlogForm from './Blog/BlogForm'
const SocialResponsibility = (props) => {
    const [modal, setModal] = useState(false);

    const toggle = () => setModal(!modal);

    return (
        <StaticQuery
            query={graphql`
      query {
        Banner: file(relativePath: { eq: "socialResponsibility.jpg" }) {
            childImageSharp {
              fluid(quality: 90, maxWidth: 1220, maxHeight:400,) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          } 
        
      }
    `}

            render={data => {
                const imageData = data.Banner.childImageSharp.fluid
                const {
                    // buttonLabel,
                    className
                } = props;
                return (
                    <Layout path="/socialresponsibility/">
                        <div className="banner_section">
                            <Img
                                Tag="section"
                                fluid={imageData}
                                className="startup_background"
                            ></Img>
                        </div>
                        <section>
                            <div className="startup_section">
                                <Container>
                                    <div className="text_center">
                                        <h3>Pay it forward</h3>
                                    </div>
                                    <Row>
                                        <Col md="12">
                                            <p>There’s more to Trident than placements and margins; profits and revenues. Trident is deeply conscious of the fact that we have a responsibility to the future generations. We believe that every single seed we sow today has the potential to grow and give. At Trident we are especially focused on education for the young and under privileged in India. We are also acutely conscious of the fact that we have a responsibility towards the community that has given us the platform to be who we are today. And therefore, our small way of giving back to our home away from home, is working with an organization that helps alleviate hunger of the homeless in the Bay Area. </p>
                                            {/* <p>There’s more to Trident than placements and margins; profits and revenues. Trident is deeply conscious of the fact that we have a responsibility to the future generations. We believe that every single seed we sow today has the potential to grow and give. At Trident we are especially focused on education for the young and under privileged in India. We are also acutely conscious of the fact that we have a responsibility towards the community that has given us the platform to be who we are today. And therefore, our small way of giving back to our home away from home, is working with an organization that helps alleviate hunger of the homeless in the Bay Area. </p> */}
                                            <p>An opportunity we cherish, and work to pay it forward.</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="6">
                                            <div>
                                                <img src={Img1} alt="img" />
                                            </div>
                                        </Col>
                                        <Col md="6">
                                            <div>
                                                <img src={Img2} alt="img" />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="4">
                                            <div className="social_main">
                                                <a href="https://ashanet.org/" target="blank">
                                                    <div className="social_icon">
                                                        <img src={Logo1} alt="img" />
                                                    </div>
                                                    <div>
                                                        <h3>Asha For Education </h3>
                                                        <p>Asha for Education is a secular organization dedicated to change in India by focusing on basic education in the belief that education is a critical requisite for socio-economic change. In keeping with this focus, our volunteers are involved with and support projects that are secular and have an education-related component to them. </p>
                                                    </div>
                                                </a>
                                            </div>
                                        </Col>
                                        <Col md="4">
                                            <div className="social_main">
                                                <a href="https://communityseva.org/about-us/" target="blank">
                                                    <div className="social_icon">
                                                        <img src={Logo2} alt="img" />
                                                    </div>
                                                    <div>
                                                        <h3>Community Seva </h3>
                                                        <p>Community Seva Inc., is a registered 501 (c) (3) organization, founded and incorporated on June 6, 2013 in California.  We are dedicated, like minded volunteers, who donate our free time to offer our skills, knowledge, and abilities and work as a team to provide assistance and support to the homeless. Our strong and capable team of 2000 + volunteers comes with an incredible array of talents – cooking skills, counseling experience, nutrition experience and much more.</p>
                                                    </div>
                                                </a>
                                            </div>
                                        </Col>
                                        <Col md="4">
                                            <div className="social_main">
                                                <a href="https://feaindia.org/" target="blank">
                                                    <div className="social_icon">
                                                        <img src={Logo3} alt="img" />
                                                    </div>
                                                    <div>
                                                        <h3>Freedom English Academy India </h3>
                                                        <p>Incepted in 2010, FEA India is a dynamic, non-governmental, non-religious, not-for-profit organization that provides young people with the skills and tools they need to break free from the cycle of poverty.
                                                           FEA provides a free program to develop English language skills, and transformative personal skills, like drive, determination, self-confidence, critical thinking, discipline, ability to postpone gratification and persistence. FEA helps students become life-long, self-directed learners, by becoming adept at using Massive Open Online Courses (MOOCs) and provides career counseling and mentorship.
                                            </p>
                                                    </div>
                                                </a>
                                            </div>
                                        </Col>
                                        <Col sm="12" md={{ size: 4, offset: 2 }}>
                                            <div className="social_main">
                                                <a href="https://www.facebook.com/RoshniChennaiNGO/" target="blank">
                                                    <div className="social_icon">
                                                        <img className="social_icon_height" src={Logo4} alt="img" />
                                                    </div>
                                                    <div>
                                                        <h3>Roshni</h3>
                                                        <p>Roshni is an NGO based in Chennai, India. We operate a school (LKG-10th std) as well as support women's initiatives across the city. Roshni was founded in 1992 with the aim of supporting displaced and disadvantaged women in the greater Chennai area. 25 years on, we also provide education for 400 children at the Roshni Matriculation School, as well as remaining true to our roots by providing vocational training and financial support to over 3000 women.</p>
                                                    </div>
                                                </a>
                                            </div>
                                        </Col>
                                        <Col md="4">
                                            <div className="social_main">
                                                <a href="http://www.bhoomikatrust.org/" target="blank">
                                                    <div className="social_icon">
                                                        <img src={Logo5} alt="img" />
                                                    </div>
                                                    <div>
                                                        <h3>Bhoomika </h3>
                                                        <p>With a primary focus on disaster relief and rehabilitation, Bhoomika Trust was set up in 2001. We have worked extensively with victims of natural disasters like the earthquake in Gujarat and the tsunami in South India. We also work towards supporting the educational needs of underprivileged individuals.</p>
                                                    </div>
                                                </a>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </section>
                        <section className="footer_section">
                            <Container>
                                <Row>
                                    <Col md="12">
                                        <h1>Find out what Trident can do for you.</h1>
                                        <div className="footer_button">
                                            <a href onClick={toggle}>Contact Us</a>
                                        </div>
                                        <Modal isOpen={modal} toggle={toggle} className={className} className="form_modal">
                                            <ModalBody >
                                                <BlogForm title="Find out what Trident can do for you." close={()=>toggle()}/>
                                            </ModalBody>
                                        </Modal>
                                    </Col>
                                </Row>
                            </Container>
                        </section>
                        {/* <BlogForm refItem={() => { }} title="Find out what Trident can do for you." redirectTitle="Social Responsibility" pathname={props.location.pathname} /> */}


                    </Layout>
                )
            }}
        />
    )
}
const StyledBackgroundSection = styled(SocialResponsibility)`
  width: 100%;
  background-position: bottom center;
  background-repeat: repeat-y;
  background-size: cover;
  margin: 0;
  background-color: inherit !important;
  
  `

export default StyledBackgroundSection